import '../../css/legal.css'

export default function TCMs() {
    return (
        <div className='container'>
            <div className='starting_section'>
                <h1 className='heading'>
                    Our Terms & Conditions for Service
                </h1>
                <h3 className='sub_heading'>
                    Last Modified: 7th March 2024
                </h3>

            </div>
            <hr></hr>
            <p>
                For information on how to use the service or for a simple cancellation of your membership, please see our help section. If you have any questions, please contact our customer care team.
            </p>
            <div className='mobile'>
                <h1>
                    1. INTRODUCTION
                </h1>
                <p>
                    <span >a) These Terms &amp; Conditions govern the relationship between MJMarry LTD (the “Company,” "we," "us," or "our") and you ("you," "your," or "yourself").</span>
                </p>
                <p>
                    <span >b) We are proud to offer an online relationship service for single adults who want to meet globally.</span>
                </p>
                <p>
                    <span >c) These Terms &amp; Conditions, together with our Privacy Policy, create a legally binding Agreement between you and us ("Agreement").</span>
                </p>
                <p>
                    <span >e) The Agreement, including any modifications made to it over time, applies to anyone who uses our Services worldwide.</span>
                </p>
                <p>
                    <span >f) As a Member, you will be able to use Services related to Company products for which you have Membership. If you meet certain requirements, the Company may choose to make your profile visible to Users on other Websites and Apps owned by us or our group companies where you do not have a Membership account.</span>
                </p>
                <p>
                    <span >g) The Agreement also applies to your use of all features, widgets, plug-ins, applications, content, downloads, and/or other services globally that:</span>
                </p>
                <p>
                    <span className='indent_2'>i. Those owned and controlled by us or made available to you;</span>
                </p>
                <p>
                    <span className='indent_2'>ii. Also place a reference of this Agreement globally.</span>
                </p>
                <p>
                    <span >h) You are reminded to comply with all relevant local laws. You also agree not to use our Services for any unlawful, harmful, threatening, abusive, harassing, or otherwise objectionable purposes. </span>
                </p>
                <p>
                    <span >If you breach these Terms &amp; Conditions we reserve the right to terminate your access to our Services.</span>
                </p>
                <h1>
                    2. DEFINITIONS
                </h1>
                <p>
                    <span >a) In these Terms &amp; Conditions:</span>
                </p>
                <p>
                    <span >-“Apps” stand for each and all of the Mobile Apps, Desktop Apps, Web App.</span>
                </p>
                <p>
                    <span >-“Desktop Apps” refers to computer desktop applications that may be published by MJMarry from time to time globally</span>
                </p>
                <p>
                    <span >-Member any person whose Membership has been accepted by MJMarry and whose Membership continues to be valid globally. The term ‘Member’ encompasses both free Members and paying ones depending on which one is used within a context .</span>
                </p>
                <p>
                    <span >-Membership refers to your right to one or more Services solely because you are a Member anywhere worldwide. Such right may differ based on whether a Membership is paid or free MJMarry membership account.</span>
                </p>
                <p>
                    <span >-“Mobile Apps” refers to the iOS app and any Android application or mobile/tablet device software applications published by MJMarry globally from time to time.</span>
                </p>
                <p>
                    <span >-“Privacy Policy” refers to the privacy policy provided here, which along with Terms &amp; Conditions forms an Agreement between you and MJMarry worldwide.</span>
                </p>
                <p>
                    <span >-Services shall include all services provided by MJMarry in any way, shape or form globally (including but not limited to the Websites,</span>
                </p>
                <p>
                    <span >-“Terms &amp; Conditions” denotes these terms and conditions that, along with the Privacy Policy form an Agreement developed between you and MJMarry globally, modified or changed according to its full discretion by MJMarry at any time while posted on Websites.</span>
                </p>
                <p>
                    <span >-User in these regulations means any Member and Visitor around the world.</span>
                </p>
                <p>
                    <span >-Visitor refers to anyone who accesses the Services worlwide.</span>
                </p>
                <p>
                    <span >-“Web Apps” refers to web apps that are published or offered by MJMarry globally.</span>
                </p>
                <p>
                    <span >-“Website(s)” refers to one or more websites run by MJMarry around the world.</span>
                </p>
                <h1>
                    3. AGE FOR USE OF MJMarry
                </h1>
                <p>
                    <span >Age limitations and parents’ or legal guardians’. MJMarry will not be used by anyone under the age of 18. You hereby declare that you are at least the age of majority in any jurisdiction where you reside or, alternatively, your parent or legal guardian must agree to these Terms and acknowledge they accept policies set forth therein on behalf of yourself &amp; hence bears responsibility for their utilization by me. When accepting these Terms on behalf of a man or an organization, you represent that you have both the capacity and authority to bind such person or entity thereto.</span>
                </p>
                <h1>
                    4. YOUR PRIVACY – COLLECTION AND RETENTION OF PERSONAL INFORMATION
                </h1>
                <p>
                    <span >a) We tell you what we do and don’t with your data in our Privacy Policy.</span>
                </p>
                <p>
                    <span >b) We do not always protect your messages and their content, we have the right to monitor these chat-messages and other data in compliance with our Terms 3 (for instance where there are reports that a chatting message breaches or is likely to breech of terms).</span>
                </p>
                <h1>
                    5. ACCOUNTS AND SECURITY
                </h1>
                <p>
                    <span >a) In order to use the Services, there must be an account.</span>
                </p>
                <p>
                    <span >b) You are to keep your logon and password confidential you must bear this responsibility.</span>
                </p>
                <p>
                    <span >c) If asked, you are obligated to present us with some kind of identification in order to confirm your identity.</span>
                </p>
                <p>
                    <span >d) The Services are available to all – provided an application is successful and approved by MJMarry in accordance with these Terms &amp; Conditions.</span>
                </p>
                <p>
                    <span >e) MJMarry obliges all Users to commit him-herself by these Terms &amp; Conditions and above especially, so take up a commitment on T&amp;C Section 6 below recognizing Code of Conduct.</span>
                </p>
                <p>
                    <span >f) You may not use our Services if:</span>
                </p>
                <p>
                    <span >-You are below 18. We do not provide Services to children and ask all young individuals under the age of 18 years old not to provide any information about themselves. Our Services are targeted at adult individuals who have reached the age of 18 years old. We also don t collect or retain personally identifiable information from those Users that we know are under the age of 18 years old. If we discover, or are notified that Users under the age of 18 years old have supplied personally identifiable information then such will be deleted immediately.</span>
                </p>
                <p>
                    <span >-You have been previously convicted of a violent or sexually related criminal offence. MJMarry does not perform criminal background screening of our Users, and we cannot identify every User MJMarry cannot be blamed for fraudulent statements by a Member. For instance, you might select a close friend or relative with whom to report any meeting and arrange the first one in public place.</span>
                </p>
                <p>
                    <span >-You were previously barred from using our Services or similar services. MJMarry shall not be responsible for any action whatsoever carried out by a User globally, including such actions accomplished during events organised either through MJMarry or using its Services globally.</span>
                </p>
                <h1>
                    6. CONDITIONS OF ADMISSION FOR MJMarry
                </h1>
                <p>
                    <span >a) In order to use our Services you must accept these Terms &amp; Conditions. They govern your use of our Services globally.</span>
                </p>
                <p>
                    <span >b) If you do not agree with the Privacy Policy and these Terms &amp; Conditions, then you cannot use our Services globally.</span>
                </p>
                <p>
                    <span >c) If you:</span>
                </p>
                <p>
                    <span >-our Services worldwide;</span>
                </p>
                <p>
                    <span >-click to join and/or sign in;</span>
                </p>
                <p>
                    <span >-That while globally, check a box to indicate acceptance of these Terms and Conditions .</span>
                </p>
                <p>
                    <span >-we will consider this to be your acceptance of these Terms &amp; Conditions and consenting thereto globally.</span>
                </p>
                <p>
                    <span >d) The Company retains discretion to admit its Services worldwide at any time.</span>
                </p>
                <p>
                    <span >e) By relying on our Services offered worldwide, you agree and warrant that having legal capacity to conclude a contract under your place of residence.</span>
                </p>
                <h1>
                    7. CODE OF CONDUCT
                </h1>
                <p>
                    <span >a) Each User is obliged to comply strictly with all laws and regulations applicable, as well as these Terms &amp; Conditions.</span>
                </p>
                <p>
                    <span >b) Each User irrevocably undertakes to refrain from:</span>
                </p>
                <p>
                    <span >-Discrimination on the basis of a User’s real or supposed: – age race color ethnicity national origin sexual orientation religion gender identity family status pregnancy physical appearance surname state of health disability genetic characteristics personal beliefs political opinion or union activities;</span>
                </p>
                <p>
                    <span >-any professional or commercial use of the Services, whether directly or indirectly; to offer , solicit o…. Our Services do not allow prostitution;</span>
                </p>
                <p>
                    <span >-Using our Services to engage in any illegal activity whatever.</span>
                </p>
                <p>
                    <span >-Posting any content or making any statement in any form which:</span>
                </p>
                <p>
                    <span >c) infringes upon, misappropriates intellectual property rights such as patents, copyrights, trademarks, trade secrets, moral rights, or other intellectual property, or privacy or publicity rights;</span>
                </p>
                <p>
                    <span >d) violates, or leads to any behavior that would violate any applicable law or regulation and could trigger civil liability;</span>
                </p>
                <p>
                    <span >e) is fraudulent, false misleading (whether directly or indirectly by omitting some information).</span>
                </p>
                <p>
                    <span >f) is defamatory, obscene or offensive; </span>
                </p>
                <p>
                    <span >g) promotes discrimination, bigotry racism hatred harassment or harm against any individual group of people.</span>
                </p>
                <p>
                    <span >h) is violent or threatening to any other person;</span>
                </p>
                <p>
                    <span >i) encourages illegal or dangerous behaviours;</span>
                </p>
                <p>
                    <span >j) that any of content associated with the above prohibitions contains a link to it otherwise violates applicable laws and regulations globally.</span>
                </p>
                <p>
                    <span >k) Spreading any personal information of another User, and particularly contact details or similar thereof without getting that User’s consent.</span>
                </p>
                <p>
                    <span >l) Each User agrees to report any abuses by other users and improper comments or conduct on the part of such Users.</span>
                </p>
                <p>
                    <span >m) We do regular checks of the use our Services globally, including those for safety and fraud prevention purposes. We retain the right to delete any content created, used, or spread publicly and privately by Users which does not conform with current laws countries worldwide that are applicable or violates these Terms &amp; Conditions.</span>
                </p>
                <p>
                    <span >n) We might call any User to ask the concerned party realign it with compliance with relevant laws or regulations, these Terms &amp; Conditions.</span>
                </p>
                <p>
                    <span >o) upon such termination or suspension, you will have no right to the refund of any unutilized fees for in-app purchases.</span>
                </p>
                <h1>
                    8. CONDITIONS OF ACCESS
                </h1>
                <p>
                    <span >a) When registering to our Services, you will pick a username.</span>
                </p>
                <p>
                    <span >b) When you register, you will be Member of that brand MJMarry for which You have registered the Services. While the Company, in its own discretion, may make your profile accessible to one or more of other Services Users if you meet these requirements it is not a Member unless he or she registers specifically.</span>
                </p>
                <p>
                    <span >c) Your username and the secret key should be private. Each Member shall keep them confidential as well and each Members agrees they will not disclose or notify such information to third parties or other members in order not to allow fraud, phishing activities that take place globally.</span>
                </p>
                <p>
                    <span >d) All Users around the world agree not to use another Member’s username or password, any other personal information of a User.</span>
                </p>
                <p>
                    <span >e) Any violation of these provisions globally may result to cancellation of a Member’s Membership, without prejudice to the liability assessment on any concerned Member due when those others make use or another person uses its username and or passcode.</span>
                </p>
                <p>
                    <span >f) Each Member is entitled not to disclose strictly personal information.</span>
                </p>
                <p>
                    <span >g) MJMarry will do whatever is required legally to ensure that fraudulent conduct ceases, including disallowing users from sharing usernames or passwords.</span>
                </p>
                <p>
                    <span >h) MJMarry reserves the option of removing:</span>
                </p>
                <p>
                    <span >-information posted or available for more than 36 months on the Websites.</span>
                </p>
                <p>
                    <span >-the account of a Member which is inactive for more than 36 months after expiry date from any subscription or never active since joining as Membership, following due verification that there are no ongoing subscriptions.</span>
                </p>
                <p>
                    <span >-accounts that we believe to be a replica of another in our network.</span>
                </p>
                <p>
                    <span >i) Each User around the world promises not to do any action that may cause interference with good work of Services and commits oneself not to spread or arrange for spreading viruses spam, logic bombs software applications etc.</span>
                </p>
                {/*<h1>
                    9. PAID FEATURES
                </h1>
                <p>
                    <span >You can also buy a paid Membership for one or more of the products that MJMarry sells. Paid Membership allows you to access only a specific MJMarry product for which your registration has taken place. Although MJMarry might in its sole discretion allow you to see your profile being visible for the Users of one or several of its other Services if so required, you won’t be an actual Member unless registered for it individually. The price and payment procedures are permanently available on the Mobile apps. All prices in the US include all applicable local taxes.</span>
                </p>
                <p>
                    <span >We have the right to alter prices of any Services we offer in US. If you are dissatisfied with the cost of any Services, you have rights to cancel your Membership consistent with these Terms &amp; Conditions. Refunds are offered within US laws and regulations to the maximum extent possible (except special cases like significant service failures in America).</span>
                </p>
                <p>
                    <span >If you haven’t exercised your right to cancel within the specified period, according with these Terms &amp; Conditions , there will not be a refund of any payment made by you for paid Membership in US.</span>
                </p>
                <p>
                    <span >If you do not pay any amount due pursuant to these Terms &amp; Conditions in the US: We assign you the right to suspend your access until we receive outstanding payment or cancel the contract without prior notice. The temporary closure will not affect the length of the contract period. In keeping with US law, we may forward any debt in arrears to an external collection agency for recovery after a reasonable period. The external agency will charge debt recovery fees on the overdue balance of the account. Ang US law you may pay 10% of the overdue account balance as a fee for debt recovery. You can show that the account balance is not actually overdue, or that this amount due was less than quoted. This clause does not diminish the claim for damages arising from delayed account balance in US.</span>
                </p>
                <p>
                    <span >We may from time to time offer paid features (for example subscriptions, micropayments etc.) through other service providers such as the Apple app store or via an “App Store” in US. Such paid features may be subject to other payment terms and conditions than those contained in these Terms &amp; Conditions. Hence, you may be asked to agree with the terms &amp; conditions of its own relevant App Store in US. Any such relevant terms will be made known to you before making a purchase. You agree to be bound by and your license to use our Services is conditioned upon complying with all applicable terms, conditions of the relevant App Store in the US.</span>
                </p>
                <p>
                    <span >Clearance Daily Instant Chats credited to our Gold membership expire if not used each day in the US. In the US, purchased Instant Chat bundles do not expire.</span>
                </p>
                <p>
                    <span >Gold member in the US will get 1 free Profile Boost per week. These free Boosts will expire after one week of issuance when you’ll be granted your next free in the US.</span>
                </p>
                <p>
                    <span >Once activated a Profile Boost in the US cannot be paused, stopped or refunded and is active for its entire duration.</span>
                </p>
                <h1>
                    10. AUTOMATIC RENEWAL
                </h1>
                <p>
                    <span >We automatically refresh all paid Memberships before the expiry of the payment term as provided by Google Play Store and Apple Appstore policies. When you initial subscribe for paid Membership with MJMarry , FAQ automatically renews your subscription that has expired, and is treated as Payable when it provides services in the US. IF YOU DO NOT WISH TO HAVE YOUR PAID MEMBERSHIP AUTOMATICALLY RENEWED, THEN AT THE END OF SIX MONTHS PERIOD IN ACCORDANCE WITH BELOW PROCESS SET OUT IN TERMS &amp; CONDITIONS.</span>
                </p>
                <h1>
                    11. CANCELLATION AND WITHDRAWAL
                </h1>
                <p>
                    <span >Cancellation should be done through the appropriate methods of cancellation used by Google Play, Apple App Stores and Stripe in US.</span>
                </p>
                <h1>
                    12. TERMINATION OF MEMBERSHIP BY MJMarry
                </h1>
                <p>
                    <span >If MJMarry declines a Member membership for breach of these Terms &amp; Conditions by the same member in US, then that member shall not be entitled to any refund nor restoration whatsoever over and above an amount due up until date on account given how fare its expiration without prejudice to further damages claimed as indemnification against loss suffered.</span>
                </p>*/}
                <h1>
                    9. TECHNOLOGY REQUIREMENTS
                </h1>
                <p>
                    <span >a) To access our Services through the technology you use, it may be necessary to meet minimum specifications provided by us.</span>
                </p>
                <p>
                    <span >b) It may be necessary for us to ask you to download and install updates from time to time the Apps. You agree and acknowledge that MJMarry may modify the Apps with or without prior notice to you around the world, coupled by any supplementary features of functions attached; further more adding or subtracting these from the applications will be upon our own discretion.</span>
                </p>
                <p>
                    <span >c) You acknowledge and agree that MJMarry has no obligation to:</span>
                </p>
                <p>
                    <span >-provide subsequent versions of the Apps to you around the world;</span>
                </p>
                <p>
                    <span >-make them available to you Apps, the Websites and/or any of the Services anywhere around the world.</span>
                </p>
                <p>
                    <span >-Continue in anyway to promote the Apps, the Websites and/or the Services globally. You concede that your access to the Apps, the Websites andor Services may not be ongoing; you are aware feature changes can occur while using these products or services globally.</span>
                </p>
                <h1>
                    10. TERMS AND CONDITIONS – UPDATES AND AMENDMENTS
                </h1>
                <p>
                    <span >MJMarry shall revise and modify these Terms &amp; Conditions at his sole discretion from time to time and such updated Terms &amp; Conditions will be accessible through the Services. You understand and agree that by using the Services from when, whenever update Terms &amp; Conditions are communicated to you shall be deemed already accepted. If at any time you do not agree to anything covered under the terms and conditions in place herein, then desist from using its services immediately.</span>
                </p>
                <h1>
                    11. SECTION 14 OF THE MJMARRY SERVICE
                </h1>
                <p>
                    <span >a) You agree and understand that the Company has all rights to change or finish any part of the Apps, websites and/or Services at its discretion without prior advise. Also they are allowed to limit your access to these through blocking you from using it in case if he would like so.</span>
                </p>
                <p>
                    <span >b. You are also liable for all data, SMS mobile carrier Internet and telecom charge so on attached to the use of Apps; Websites or Services.</span>
                </p>
                <p>
                    <span >c) You agree that the Company can take any action to disable or deny you access, at its sole discretion without giving reasons.Such actions include but are not limited to denying your use of Apps, the Websites and/or Services including for violating provisions contained in these Terms &amp; Conditions , if it suspects that through their service; fraudulent and illegal activities have been performed on If the Company denies or revokes your access to your account, then you will not be able to log into any of their Services nor view details about and materials contained in your account globally.</span>
                </p>
                <h1>
                    12. LICENSE RESTRICTIONS AND USE CONDITIONS
                </h1>
                <p>
                    <span >a) Subject to the terms and conditions of this Agreement and for the sole purpose of using the Services, MJMarry hereby grants you a limited, non-exclusive, revocable, non-sublicensable, non-transferable license to: install any or all the Mobile Apps on one or more mobile devices which are yours; those you control and meet MJMarry’s minimum specifications, install any of all Desktop App</span>
                </p>
                <p>
                    <span >b) You must not access, or attempt to access the Services through any means other than by use of the Websitesor Apps. Specifically, you agree not to access or try and use the Services through any automated means such as scripts, bots unauthorized third-party applications spiders web crawlers.</span>
                </p>
                <p>
                    <span >c) You agree that you will not, in connection with your use of the Apps ,the Websites and/or the Services violate any applicable law ordinance rule regulation or treaty around the world.</span>
                </p>
                <p>
                    <span >d) You shall not connect to or use the Apps, Websites and/or Services in a manner which is otherwise restricted by this terms &amp; conditions as specified above.</span>
                </p>
                <p>
                    <span >e) You may not:</span>
                </p>
                <p>
                    <span >-do not put any proprietary markings on the Services or with respect to Software;</span>
                </p>
                <p>
                    <span >-prevent, allow or facilitate the modification of the Apps; change changes to any Software on whichServices are based.</span>
                </p>
                <p>
                    <span >-be sold, assigned or rented out; leased through sublicence; acted upon as a service bureau to allow another person-entity whatsoever anywhere in the Apps , The Services and any Software created underneath MJMarry without prior written consent.</span>
                </p>
                <p>
                    <span >-make any false misleading or deceptive statement representation, either written verbally regarding MJMarry and or the Apps, Websites , etc.</span>
                </p>
                <p>
                    <span >f) Without limiting the foregoing, YOU AGREE THAT YOU WILL NOT:</span>
                </p>
                <p>
                    <span >-engage in any activity that:</span>
                </p>
                <p>
                    <span >1) discrimination, bigotry, racism hate harassment or harm against any person or community worldwide.</span>
                </p>
                <p>
                    <span >2) is derogatory, obscene or indecent globally;</span>
                </p>
                <p>
                    <span >3) is violent or threatening, or promotes violence or actions that are threatening to another person around the world; d. infringes upon a third party’s patent, copyright , trademark trade secret , moral rights,.</span>
                </p>
                <p>
                    <span >-use, without the consent of other User globally any personal information or contact details which pertains to that particular user.</span>
                </p>
                <p>
                    <span >-....institute, assist or participate in any form of attack including but not limited to denial service attach upon the Apps ,the Websites and/or Services (and / or servers systems network associated with the said items) globally</span>
                </p>
                <p>
                    <span >-try to hinder, decelerate or otherwise conduct interference with the performance of Apps, Websites and Services globally.</span>
                </p>
                <p>
                    <span >-Attempt to hack the Apps, the Websites, or any account registered with another User globally;</span>
                </p>
                <p>
                    <span >-use the Websites, Apps or Services.</span>
                </p>
                <p>
                    <span >1) develop, create, send or save information that is illegal; offensive by reason of race\ ethnic origin ; defamatory; encourages behavior deemed a criminal offense globally.</span>
                </p>
                <p>
                    <span >2) make any unsolicited commercial communication that is not authorized by an applicable law in countries around the world.</span>
                </p>
                <p>
                    <span >g) Each User agrees to contact MJMarry regarding any abuses, as well as improper comments or behavior by other Users globally.</span>
                </p>
                <p>
                    <span >h) We periodically review our Services use from time to time for the purpose of safety and fraud prevention. We also reserve the right to dwell all or part of any content created, used or shared publicly/privately by Users that is not in compliance with applicable laws and regulations globally ,or violates these Terms &amp; Conditions.</span>
                </p>
                <p>
                    <span >i) We may reach out to any User and ask you to correct non-compliance with your country applicable laws or regulations, these Terms&amp; Conditions.</span>
                </p>
                <p>
                    <span >j) around the world, MJMarry may at its option terminate its relationship with you or disable your account immediately if it feels that you are using these Services contrary to limitations imposed on this Section 15 or in any other terms of this Agreement.</span>
                </p>
                <p>
                    <span >k) We may deny any User the right to enjoy our Services or terminate access of such user for free use of our services frombud in accordance with its resolution and without having an obligation to give reasons, whether that ser vice will be related “to violation by a particular individual about some laws applying both globally and these Terms &amp; Conditions . MJMarry is not responsible or liable to you and no other person of any entity for your breach of these Terms &amp; Conditions nor any consequences arising from such a breach globally.</span>
                </p>
                <h1>
                    13. LINKS TO OTHER WEBSITES
                </h1>
                <p>
                    <span >a) Our Services can include links to Other Sites and resources offered by third parties. 1 The Other Sites are linked to share only the information and they serve just for your convenience worldwide.</span>
                </p>
                <p>
                    <span >b) MJMarry cannot control the content or products or services available on Other Sites globally, and he does not accept any liability for loss of damage suffered by you from using it.</span>
                </p>
                <p>
                    <span >c) If you decide to visit Other Sites around the world, do so at your own risk and according to their terms of use – if available , as well as in accordance with such site’s privacy policy.</span>
                </p>
                <p>
                    <span >d) 3rd parties may advertise their products globally through Our Services as well. Therefore, MJMarry manages to not make any representations or warranties in respect of the products and services advertised globally by third parties on our Services.</span>
                </p>
                <h1>
                    14. INTELLECTUAL PROPERTY
                </h1>
                <p>
                    <span >a) All intellectual property rights (including the various rights conferred by statute, common law and equity in and in relation to copyright, patents, trademarks, service marks, trade names and/or designs (including the “look and feel” and other visual or non-literal elements) (whether registered or unregistered) in:</span>
                </p>
                <p>
                    <span >-our Services;</span>
                </p>
                <p>
                    <span >-Information content on our Services subject to clause 17.3; and</span>
                </p>
                <p>
                    <span >-All design, text and graphics, software programs including applets and scripts; sounds music photos videos at the same way selection their arrangement are protected by Copyright of MJMarry or licensed to us. shall not nor attempt to acquire any right whatsoever for such intellectual property. All rights are reserved.</span>
                </p>
                <p>
                    <span >b) MJMarry does not permit any of the material listed in clause 17.1 to be reproduced or redistributed, copied distributed republished downloaded displayed posted such as transmitted in whatever form by whoever way it they may no without prior express written consent of MJ . However, you may retrieve and display the content of our Services on a computer screen ( including any tablet or smart phone device) , save such content in electronic form on disk ( but not ordinarily through storage devices attached to networks), make one copy personally for non-commercial use incorporating all copyright protections. You cannot copy, replicate or republish any of the materials or content on our Services otherwise without MJMarry’s express prior written consent to do so.</span>
                </p>
                <p>
                    <span >c) Title, With regards to both ethical and legal implications Intellectual property right and ownership rights act as properties of the content owner or supplier. Based on this factor, the content is to be protected by suitable law, i.e., copyright, trademark or any other legislation. Considering the clause 172, the agreement authenticates confidentiality and gives no right to anyone to use the content apart from the rightful owner.</span>
                </p>
                <p>
                    <span >d) The authors of the literary and artistic works set forth in our Services have made a claim about their moral rights to be identified as an author of those works.</span>
                </p>
                <p>
                    <span >&#xa0;</span>
                </p>
                <p>
                    <span >e) Any information you send, upload or write to us MJMarry either via our Services of otherwise (Material) shall be regarded as confidential and we may treat it accordingly unless obliged by privacy legislation globally You hereby grant MJMarry a non-exclusive, royalty free licence to use all Material set out below in any media whatsoever world wide for and from the date that you first post or submit them to this web site until termination of your membership; Such usage includes but is not limited</span>
                </p>
                <p>
                    <span >f) All comments, suggestions, ideas , notes and drawings of any kind or concepts in which intellectual property rights subsist that are disclosed to MJMarry by me you 0r offered as a result of an approach following solicitations from us regarding our services (Ideas) shall be deemed all tbe Idea s oered .The said assignment includes all existing present and future ownership To assure further of the assignment of such rights you must do all things reasonably requested by MJMarry. You are aware and agree that MJMarry has both internal resources of some sort as well as different other external resources which may have already developed or will in the future grow to develop concepts like Ideas and therefore it is only willingTHORugh those terms. However, Ideas are not confidential and MJMarry has no obligation express or implied in considering it. By way of instance, but without limitation MJMarry shall be the proprietor as well as beneficial user of all now known or hereafter arising rights to each and every Idea in any form whatsoever by means’ statistically conclusions globally.&lt;/s&gt;::rest</span>
                </p>
                <h1>
                    15. DISCLAIMER OF WARRANTIES
                </h1>
                <p>
                    <span >a) You understand and accept that your use of MJMarry is at your own risk.</span>
                </p>
                <p>
                    <span >b) MJMarry is offered with no warranties or conditions, whether express or implied (to the extent permitted by law).</span>
                </p>
                <p>
                    <span >c) The Company disclaims all warranties and conditions, to the extent permitted by applicable law. These include without limitation legal guarantees of satisfactory quality, merchantability, fitness for a particular purpose; non-infringement injunctions; implied rules from course of dealing or usages with vendors.</span>
                </p>
                <p>
                    <span >d) The Company provides no warranty regarding the accuracy, completeness or reliability of any materials, information data available through MJMarry or performance by it.</span>
                </p>
                <p>
                    <span >e) The Company does not represent or warrant that:</span>
                </p>
                <p>
                    <span >-you will be able to use MJMarry when and where globally</span>
                </p>
                <p>
                    <span >-that MJMarry’s operation will run smoothly, on time and without error around the world;</span>
                </p>
                <p>
                    <span >-your usage of MJMarry will satisfy all global needs;</span>
                </p>
                <p>
                    <span >-any defects in MJMarry’s operation will be resolved globally.</span>
                </p>
                <p>
                    <span >-MJMarry is not contaminated with viruses or other harmful constituents.</span>
                </p>
                <p>
                    <span >f) You agree and acknowledge that any material downloaded or otherwise obtained through the use of MJMarry is at your own risk, you are solely responsible for damage to your computer mobile phone as well as other device or loss of data due to malware from this foreign entity.</span>
                </p>
                <p>
                    <span >g) Some countries do not permit disclaiming implied warranties. In such jurisdictions, the Company explicitly disclaims all warranties and conditions to the greatest extent allowed by law.</span>
                </p>
                <h1>
                    16. LIMITATION OF LIABILITY
                </h1>
                <p>
                    <span >a) To the maximum extent permitted by applicable law, MJMarry, its subsidiaries or holding company, any subsidiary of any such holding company, affiliates, successors, assigns, and their respective employees, agents, directors, officers and shareholders, whether individually or collectively (the “Related Parties”) shall not be liable to you, under any contract, tort (including negligence), strict liability or other legal or equitable theory, amongst other things:</span>
                </p>
                <p>
                    <span >-Any direct, indirect, incidental; consequential or special damages of any character including without limitation loss of profits (whether business interruption losses or otherwise), use data and goodwill arising out</span>
                </p>
                <p>
                    <span >-The cost of acquiring alternative goods; services or technology.</span>
                </p>
                <p>
                    <span >-The removal, falsification or failure to retain any materials, information and data that is maintained by you through your use of the Apps as well as on the Websites and Services.]</span>
                </p>
                <p>
                    <span >d) Liability of the Related Parties for such jurisdictions shall be limited to any extent permitted by law.</span>
                </p>
                <p>
                    <span >e) The Related Parties will not be responsible for any loss or damage which is unforeseeable by you.</span>
                </p>
                <p>
                    <span >&#xa0;</span>
                </p>
                <p>
                    <span >f) Nothing in these Terms &amp; Conditions limits or excludes the liability of MJMarry for:</span>
                </p>
                <p>
                    <span >-any death or personal injury resulting from its negligence;</span>
                </p>
                <p>
                    <span >-any fraud or deceit executed by MJMarry ;</span>
                </p>
                <p>
                    <span >-any damages caused wilfully;</span>
                </p>
                <p>
                    <span >-liability which can’t be limited or excluded by law in any form.</span>
                </p>
                <h1>
                    17. INDEMNIFICATION
                </h1>
                <p>
                    <span >a) You agree to, and you hereby, defend, indemnify, and hold the Related Parties harmless from and against any and all claims, damages, losses, costs, investigations, liabilities, judgments, fines, penalties, settlements, interest, and expenses (including attorneys’ fees) that directly or indirectly arise from or are related to any claim, suit, action, demand, or proceeding made or brought against any Related Party, or on account of the investigation, defense, or settlement thereof, arising out of or in connection with, whether occurring heretofore or hereafter:</span>
                </p>
                <p>
                    <span >-any data you post or otherwise made available on MJMarry.</span>
                </p>
                <p>
                    <span >-your Material;</span>
                </p>
                <p>
                    <span >-your participation in MJMarry and activities related to it, such as communications or meetings with Members you encounter through MJMarry.</span>
                </p>
                <p>
                    <span >-your violation or claimed breach of this Agreement, these additional terms;</span>
                </p>
                <p>
                    <span >-your breach or alleged violations of any laws, rules, regulations codes statutes ordinances orders according to the agency that governs them with regard to you using MJMarry and activities associated within of service;</span>
                </p>
                <p>
                    <span >-any information or material transmitted through your computer or other apparatus which infringes, violates, misappropriate his/her copyright mark trade secret the dressed; she/he publicity, privacy rule right being submitted by him/her.</span>
                </p>
                <p>
                    <span >-the misrepresentation that you make.</span>
                </p>
                <p>
                    <span >-the information that you provide us (including your Material) specifically for use by Related Parties globally to avoid any claim or losses arising from such deadly actions.</span>
                </p>
                <p>
                    <span >b) You will cooperate fully as required by Related Parties in the defense of any Claims and Losses. In cases regarding this, however related parties reserve the rights to settle or compromise and pay all Claims and Losses. Related Parties also have the right to handle exclusively any Claims and Losses. You shall not pay any Claims and Losses without, in every case, the previous written consent of an officer from a Related Party.</span>
                </p>
                <h1>
                    18. GOVERNING LAW; JURISDICTION
                </h1>
                <p>
                    <span >a) The governing laws for the construement of these Terms &amp; Conditions shall be local law, other than CISG — UN Convention on Contracts for International Sale of Goods altogether not application except principles based upon conflict with foreign laws.</span>
                </p>
                <p>
                    <span >b) Other than as provided below, you irrevocably waive the exclusion jurisdiction of local courts.</span>
                </p>
                <p>
                    <span >c) Your consumer rights will not be affected by the governing law pursuant to local laws and regulations on consumers.</span>
                </p>
                <p>
                    <span >d) You agree that a breach of any provision hereof would be irreparable harm to MJMarry and therefore you consent that MJMarry shall, without bond other security or proof of damages, have appropriate equitable remedies with respectful in addition to such other remedy may available for them under the applicable laws concerning these Terms&amp;Conditions.</span>
                </p>
                <h1>
                    19. GENERAL
                </h1>
                <p>
                    <span >a) These Terms &amp; Conditions and the Privacy Policy form the whole contract between you and MJMarry. If any part of this Agreement is declared to be unenforceable or invalid by a court having proper jurisdiction or competent authority globally, then that provision shall not affect the validity and effectiveness of whatever other parts are present as well in addition to whichever greater portion remains operative from such affected one.</span>
                </p>
                <p>
                    <span >b) The Agreement does not establish a partnership, joint venture or principal and agent relationship between the parties.</span>
                </p>
                <p>
                    <span >c) No waiver by MJMarry on any breach of this Agreement shall be considered as a consideration for the contractual obligation undertaken globally.</span>
                </p>
                <p>
                    <span >d) MJMarry may transfer any rights under these Terms &amp; Conditions to anyone or anything globally without your permission. However, you may not assign or sublet your rights under these Terms &amp; Conditions without MJMarry’s prior written consent; any attempted unauthorized assignment by you shall be considered null and void.</span>
                </p>
                <p>
                    <span >e) Article 17 to 24 inclusively will live beyond the termination or end of this Agreement.</span>
                </p>
                <h1>
                    20. FORCE MAJEURE
                </h1>
                <p>
                    <span >-"Force Majeure Event" is defined as any act or occurrence for the purposes of this clause 24.6 that is beyond the reasonable control of the Company. Examples of such acts or events include civil unrest, riots, invasions, terrorist attacks or threats of terrorist attacks, war (whether declared or not), preparations for war, fire, explosions, storms, floods, earthquakes, subsidence, epidemics, or other natural disasters, as well as failures of public or private telecommunications networks.</span>
                </p>
                <p>
                    <span >-around the world, MJMarry would not be responsible for any non-performance or delay in performance of its obligations under these Terms &amp; Conditions due to a Force Majeure Event.</span>
                </p>
                <p>
                    <span >-If any Force Majeure Event occurs that causes Services unable to be provided for 14 days or more, either party may terminate this Agreement with immediate effect upon written notice to the other and neither will have rights against each other of claiming compensation from one another.</span>
                </p>
            </div>
        </div>
    )
}