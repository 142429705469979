import logo from "../../pictures/logo.png";
import React from "react";
import ARROW_ICON_URL from "../../../assets/icons/downArrow.png"
import APP_STORE_ICON_URL from "../../../assets/icons/apple/app-store-badge.svg"
import GOOGLE_PLAY_ICON_URL from "../../../assets/icons/play/google-play-badge.svg"

import { useState } from "react";
import { useTranslation } from 'react-i18next';

const APP_STORE_URL = "#";
const GOOGLE_PLAY_URL = "https://play.google.com/store/apps/details?id=com.iahtasham.mjmarry&pli=1";

//const DateVideo = "https://mjmarry.blob.core.windows.net/assets/videos/date_3840_2160_25fps.mp4"
//const DinnerPortraitVideo = "https://mjmarry.blob.core.windows.net/assets/videos/dinner_1080_1920_25fps.mp4"
//const DinnerLandscapeVideo = "https://mjmarry.blob.core.windows.net/assets/videos/dinner_1920_1080_25fps.mp4"
const PhotographyVideo = "https://mjmarry.blob.core.windows.net/assets/videos/photography_3840_2160_25fps.mp4"
const PhotographyVideo1080p = "https://mjmarry.blob.core.windows.net/assets/videos/photography_1080_25fps.mp4"
//const WalkingVideo = "https://mjmarry.blob.core.windows.net/assets/videos/walking_3840_2160_25fps.mp4"
const PhotographyWebm = "https://mjmarry.blob.core.windows.net/assets/videos/photography_3840_2160_25fps.webm"
const PhotographyWebm720p = "https://mjmarry.blob.core.windows.net/assets/videos/photography_720_25fps.webm"
export function HeroSection() {
    const [showMessage, setShowMessage] = useState(false);
    const handleAppStoreClick = (event) => {
        event.preventDefault(); // Prevent default action for the link
        setShowMessage(true);
        
        setTimeout(() => {
            setShowMessage(false);
        }, 3000);// Show the "Coming Soon" message
    };

    

    const { t, i18n } = useTranslation();
    return (
        <section className="position-relative d-flex justify-content-center align-items-center vh-100 overflow-hidden">
            <video style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                objectFit: 'cover',
                zIndex: 0
            }} autoPlay loop muted playsInline>
                {/*<source src={PhotographyVideo1080p} type="video/mp4"/>*/}
                <source src={PhotographyWebm720p} type="video/webm" />
            </video>

            {/* Dark Overlay */}
            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black
                zIndex: 1, // Higher z-index to overlay the video
            }} />

            <div className="content position-absolute text-center"
                style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 2 }}>
                <img src={logo} alt="Logo" style={{ maxHeight: '140px' }} />
                <h2 className="text-white" style={{ fontSize: '48px', lineHeight: '40px', letterSpacing: '-2px' }}>
                    {t('wmjm')}
                </h2>
                <p className="text-white" style={{ fontSize: '18px' }}>
                    {t('quran ayat')}
                </p>
                <div className="mt-3 d-flex justify-content-center">
                    <a
                        href={APP_STORE_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="me-2"
                        onClick={handleAppStoreClick}
                    >
                        <img src={APP_STORE_ICON_URL} alt="App Store icon" style={{ width: '150px' }} />
                    </a>
                    <a href={GOOGLE_PLAY_URL} target="_blank" rel="noopener noreferrer">
                        <img src={GOOGLE_PLAY_ICON_URL} alt="Google Play icon" style={{ width: '150px' }} />
                    </a>
                </div>
                {showMessage && (
                    <div
                        style={{
                            color: 'white',
                            backgroundColor: '#ff4d4f',
                            borderRadius: '8px',
                            padding: '10px 20px',
                            marginTop: '15px',
                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                            animation: 'fadeIn 0.5s ease-in-out',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            textAlign: 'center',
                        }}
                    >
                        🚀 Coming Soon!
                    </div>
                )}
                <style>
                    {`
                @keyframes fadeIn {
                    from {
                        opacity: 0;
                        transform: translateY(-10px);
                    }
                    to {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
                `}
                </style>
            </div>
            <div className="position-absolute bottom-0 mb-2 w-100 text-center">
                <a href="#connecting-muslims">
                    <img src={ARROW_ICON_URL} alt="Continue to main content arrow"
                        style={{ height: '35px', cursor: 'pointer' }} />
                </a>
            </div>
        </section>

    );
}