import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { HeroSection } from "./HeroSection";
import { ReviewsSection } from "./ReviewsSection";

import { useTranslation } from "react-i18next";

import pic1 from "../../pictures/about/1.jpg";
import pic2 from "../../pictures/about/2.jpg";
import pic3 from "../../pictures/about/3.jpg";
import pic4 from "../../pictures/about/4.jpg";
import pic5 from "../../pictures/about/5.jpg";

export default function HomePage() {
  const { t, i18n } = useTranslation();

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function getFeatureTitle() {
    return t("mt");
  }
  function getFeatureDescription() {
    return t("md");
  }

  function getChatTitle() {
    return t("ct");
  }
  function getChatDescription() {
    return t("cd");
  }

  function getVideoTitle() {
    return t("vt");
  }
  function getVideoDescription() {
    return t("vd");
  }

  function getPrivacyTitle() {
    return t("cpt");
  }
  function getPrivacyDescription() {
    return t("cpd");
  }

  function getReligiousTitle() {
    return t("rt");
  }
  function getReligiousDescription() {
    return t("rd");
  }

  function getChaperonesTitle() {
    return t("cht");
  }
  function getChaperonesDescription() {
    return t("chd");
  }

  function getKhatunaTitle() {
    return t("kt");
  }
  function getKhatunaDescription() {
    return t("kd");
  }

  const sectionsData = [
    {
      id: "feature",
      title: getFeatureTitle(),
      description: getFeatureDescription(),
      images: {
        png: [pic1],
      },
    },
    {
      id: "chat",
      title: getChatTitle(),
      description: getChatDescription(),
      images: {
        png: [pic2],
      },
    },
    {
      id: "video",
      title: getVideoTitle(),
      description: getVideoDescription(),
      images: {
        png: [pic3],
      },
    },
    {
      id: "privacy",
      title: getPrivacyTitle(),
      description: getPrivacyDescription(),
      images: {
        png: [pic4],
      },
    },
    {
      id: "religion",
      title: getReligiousTitle(),
      description: getReligiousDescription(),
      images: {
        png: [pic4],
      },
    },
    {
      id: "khatuna",
      title: getKhatunaTitle(),
      description: getKhatunaDescription(),
      images: {
        png: [pic5],
      },
    },
  ];

  const Section = ({ title, description, images, index, width }) => (
    <section
      className="container mx-auto d-flex flex-column flex-lg-row align-items-center justify-content-center text-center text-lg-left"
      style={{ gap: "100px", padding: "4rem 0" }}
    >
      {width <= "576" && (
        <>
          <div>
            <h2
              style={{
                fontSize: "34px",
                fontWeight: "bold",
                lineHeight: "48px",
                letterSpacing: "-1px",
                marginTop: "",
              }}
            >
              {title}
            </h2>
            <div
              style={{
                fontSize: "18px",
                lineHeight: title === "The App for all Muslims" ? "15px" : "27px",
                fontWeight: "light",
                alignItems: "center"
              }}
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>
          </div>

          <div style={{ maxWidth: "400px" }}>
            <picture>
              <source
                media="(max-width: 1535px)"
                srcSet={images.png[0]}
                type="image/png"
              />
              <img
                className="d-block w-100"
                src={images.png[0]}
                alt={title}
                loading="lazy"
                style={{ objectFit: "cover", objectPosition: "70%" }}
              />
            </picture>
          </div>
        </>
      )}

      {width > "576" && (
        <>
          {index % 2 !== 0 && (
            <div style={{ maxWidth: "400px" }}>
              <h2
                style={{
                  fontSize: "40px",
                  fontWeight: "bold",
                  lineHeight: "48px",
                  letterSpacing: "-1px",
                  marginTop: "20px",
                }}
              >
                {title}
              </h2>
              <div
                style={{
                  fontSize: "18px",
                  lineHeight: "27px",
                  fontWeight: "light",
                }}
                dangerouslySetInnerHTML={{ __html: description }}
              ></div>
            </div>
          )}

          <div style={{ maxWidth: "500px" }}>
            <picture>
              <source
                media="(max-width: 1535px)"
                srcSet={images.png[0]}
                type="image/png"
              />
              <img
                className="d-block w-100"
                src={images.png[0]}
                alt={title}
                loading="lazy"
                style={{ objectFit: "cover", objectPosition: "70%" }}
              />
            </picture>
          </div>

          {index % 2 === 0 && (
            <div style={{ maxWidth: "400px" }}>
              <h2
                style={{
                  fontSize: "40px",
                  fontWeight: "bold",
                  lineHeight: "48px",
                  letterSpacing: "-1px",
                  marginTop: "20px",
                }}
              >
                {title}
              </h2>
              <div
                style={{
                  fontSize: "18px",
                  lineHeight: "27px",
                  fontWeight: "light",
                }}
                dangerouslySetInnerHTML={{ __html: description }}
              ></div>
            </div>
          )}
        </>
      )}
    </section>
  );

  return (
    <>
      <HeroSection />
      <div style={{ background: "white" }}>
        <div>
          {sectionsData.map((section, index) => (
            <Section
              key={section.id}
              {...section}
              index={index}
              width={width}
            />
          ))}
        </div>
        <ReviewsSection />
      </div>
    </>
  );
}
